// var d = new Date();
// d.setHours(0, 0, 0, 0);
// const today_start = Math.floor(d.getTime() / 1000) - 2*7*24*60*60;
// const today_now = Math.floor(new Date().getTime() / 1000);

import { endOfWeek, getUnixTime, startOfWeek } from "date-fns";

const currentDate = new Date();

const startDate = startOfWeek(currentDate);
const endDate = endOfWeek(currentDate);
endDate.setHours(23, 59, 59);
const initialState = {
  loaded: false,
  date: {
    mode: "Weekly",
    start_date: getUnixTime(startDate),
    end_date: getUnixTime(endDate),
  },
  graph: {
    resolved_edges: [],
    resolved_nodes: [],
    communication_load: [],
    resolved_talk_time: {},
    resolved_meeting_time: {},
    graph_metric_trends: {},
    network_metrics: {},
  },
  graphV2: {
    resolved_edges: [],
    resolved_nodes: [],
    trends: [],
    meeting_time: [],
  },
  meetingGraph: {},
  metric_params: {},
};

const graphReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_GRAPH_DATA_SUCCESS":
      console.log("hello reducer1");
      return {
        ...state,
        loaded: true,
        graph: action.payload,
      };
    case "UPDATE_DATE":
      console.log("hello reduce2r");
      return {
        ...state,
        date: { ...state.date, ...action.payload },
      };
    case "FETCH_GRAPH_DATA_SUCCESS_V2":
      console.log("hello reducer3");
      return {
        ...state,
        loaded: true,
        graphV2: action.payload,
      };
    case "FETCH_GRAPH_DATA_LOADING_V2":
      console.log("hello reducer4");
      return {
        ...state,
        loaded: false,
      };
    case "FETCH_GRAPH_METRIC_PARAMS_V2":
      console.log("hello reducer5");
      return {
        ...state,
        metric_params: action.payload,
      };
    case "FETCH_MEETING_GRAPH":
      console.log("hello reducer6");
      if (action.payload.key in state.meetingGraph) {
        return state;
      } else {
        return {
          ...state,
          meetingGraph: {
            ...state.meetingGraph,
            [action.payload.key]: action.payload.value,
          },
        };
      }
    default:
      return state;
  }
};

export default graphReducer;
