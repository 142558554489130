import React, { useEffect, useState } from "react";

import { Provider } from "react-redux";
import store from "../../store/rootStore";

import ASSETS from "../../assets/Assets";
import Checkbox from "@mui/material/Checkbox";

import axios from "axios";

import { HiMailOpen } from "react-icons/hi";

import { createOrganisation, createUserAPI } from "../../api/signup_v2";

import { useSearchParams } from "react-router-dom";

import {
  Authenticator,
  useAuthenticator,
  Heading,
  useTheme,
  TextField,
  Button as AwsButton,
  Alert,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import { verifyOrganisationAPI } from "../../api/verifyOrganisationCode";

import "./AuthApp.css";

function AuthApp({ App }) {
  const { user, route, toSignUp, toSignIn, signOut } = useAuthenticator(
    (context) => [context.user]
  );

  const [status, setStatus] = useState("loading");
  const [authComponent, setAuthComponent] = useState("signIn");
  const [
    showOrganisationVerificationComponent,
    setShowOrganisationVerificationComponent,
  ] = useState(false);
  const [verificationCode, setVerifcationCode] = useState("");
  const [wrongVerificationCodeAlert, setWrongVerificationCodeAlert] =
    useState(false);
  const [awsVerifyButtonState, setAWSVerifyButtonState] = useState(false);
  const [organisation, setOrganisation] = useState("");

  const toVerfiyOrganisationCode = () => {
    setShowOrganisationVerificationComponent(true);
    //setAuthComponent("signUp");
    setAuthComponent("signIn");
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const currentSetting = searchParams.get("signup");
  console.log(currentSetting);
  const currentCode = searchParams.get("verification_code");
  console.log(currentCode);

  const [userSignUp, setUserSignUp] = useState(false);

  const handleSelectSignUpTab = (verificationCode) => {
    searchParams.set("signup", true);
    searchParams.set("verification_code", verificationCode);

    setSearchParams(searchParams);
  };

  const verifyOrganisation = async () => {
    setAWSVerifyButtonState(true);
    //Calling the api
    const response = await verifyOrganisationAPI(verificationCode);
    if ("organisation_id" in response.data) {
      console.log(response.data);

      handleSelectSignUpTab(verificationCode);

      // setOrganisation(response.data);
      setShowOrganisationVerificationComponent(false);
      setVerifcationCode("");
    } else {
      setWrongVerificationCodeAlert(true);
    }
    setAWSVerifyButtonState(false);
  };

  const toSignInComponent = () => {
    console.log("to sign in component");

    searchParams.delete("signup");
    searchParams.delete("verification_code");
    setSearchParams(searchParams);

    setShowOrganisationVerificationComponent(false);
    setAuthComponent("signIn");
    toSignIn();
  };

  useEffect(() => {
    if (typeof user === "undefined") {
      setStatus("unauthenticated");
    } else if (route === "confirmSignUp") {
      setStatus("unauthenticated");
    } else {
      setStatus("authenticated");
    }
    console.log(user);
  }, [user, route]);

  const logOut = () => {
    setStatus("unauthenticated");
    signOut();
  };

  const formFields = {
    signIn: {
      username: {
        label: "",
        placeholder: "Email",
      },
      password: {
        label: "",
        placeholder: "Password",
      },
    },
    signUp: {
      "custom:org_verification_id": {
        order: 1,
        name: "custom:org_verification_id",
        type: "custom:org_verification_id",
        // style: {
        //   display: "none",
        //   border: "none",
        // },
      },
      "custom:org_name": {
        order: 2,
        name: "custom:org_name",
        type: "custom:org_name",
      },
      "custom:app_role": {
        order: 3,
        name: "custom:app_role",
        type: "custom:app_role",
      },
      name: {
        order: 4,
        label: "",
      },
      email: {
        order: 5,
        label: "",
      },
      password: {
        order: 6,
        label: "",
      },
      confirm_password: {
        order: 7,
        label: "",
        required: true,
      },
    },
  };

  // const services = {
  //   async handleSignUp(formData) {
  //     console.log(formData);
  //     console.log(organisation);
  //     let { username, password, attributes } = formData;

  //     // setting custom attributes
  //     // attributes["custom:organisation_id"] = organisation.organistaion_id;
  //     // attributes["custom:organisation_name"] = organisation.organistaion_name;
  //     // attributes["custom:app_role"] = organisation.role;
  //     // return Auth.signUp({
  //     //   username,
  //     //   password,
  //     //   attributes,
  //     // });
  //   },
  // };

  const signupHelper = async () => {
    const res = await createUserAPI(currentCode);

    console.log("result ------> ", res.data);

    if (res.data === "Ok") {
      toSignInComponent();
    }

    const signup_data = res.data[0];

    // Setting Organisation Id
    let input = document.querySelector(
      'input[name="custom:org_verification_id"]'
    );
    if (input) {
      input.setAttribute("value", currentCode);
      input.setAttribute("readonly", true);
    }

    //Setting Organisation Name
    input = document.querySelector('input[name="custom:org_name"]');
    if (input) {
      input.setAttribute("value", signup_data.organisation_name);
      input.setAttribute("readonly", true);
    }

    // Setting Role
    input = document.querySelector('input[name="custom:app_role"]');
    if (input) {
      input.setAttribute(
        "value",
        "role" in signup_data
          ? signup_data.role
          : signup_data.verification_id_admin === currentCode
          ? "admin"
          : "staff"
      );
      input.setAttribute("readonly", true);
    }

    // test name
    if ("user_name" in signup_data) {
      input = document.querySelector('input[name="name"]');
      console.log(input);

      input.setAttribute("value", signup_data.user_name);
      input.setAttribute("readonly", true);
    }

    // test name
    if ("email" in signup_data) {
      input = document.querySelector('input[name="email"]');
      console.log(input);

      input.setAttribute("value", signup_data.email);
      input.setAttribute("readonly", true);
    }
  };

  useEffect(() => {
    if (
      currentSetting === null ||
      currentSetting === false ||
      currentCode === null
    ) {
      return;
    }

    // delay(2000);

    toSignIn();
    toSignUp();

    signupHelper();
  }, [currentCode]);

  const [org_details, set_org_details] = useState({
    organisation_name: null,
    number_of_employee: null,
    organisation_email: null,
    your_name: null,
    your_designation: null,
    terms: null,
    privacy: null,
  });

  console.log(org_details);

  const update_org_details = (metric, value) => {
    set_org_details({ ...org_details, [metric]: value });
  };

  console.log("authComponent", authComponent);
  console.log(
    "showOrganisationVerificationComponent",
    showOrganisationVerificationComponent
  );

  const [loadCreateOrg, setLoadCreateOrg] = useState(false);

  const createOrgHelper = async () => {
    setLoadCreateOrg(true);

    const res = await axios.post("https://sheetdb.io/api/v1/eas7cwb3u7y0u", [
      {
        "Sr No.": "INCREMENT",
        "Query Time": "DATETIME",
        "Organisation Name": org_details.organisation_name,
        "Name of POC": org_details.your_name,
        "Designation of POC": org_details.your_designation,
        "Organisation Email": org_details.organisation_email,
        "Number of employees": org_details.number_of_employee,
      },
    ]);

    console.log(res);

    const rep = await createOrganisation(
      org_details.organisation_email,
      org_details.organisation_name,
      org_details.number_of_employee,
      org_details.your_name,
      org_details.your_designation
    );

    console.log(rep);

    setLoadCreateOrg(false);

    // redirect to account creation page
    handleSelectSignUpTab(rep.data.verification_code_admin);
  };

  return (
    <Provider store={store}>
      {status === "authenticated" ? (
        <App logOut={logOut} />
      ) : status === "unauthenticated" ? (
        <div className="auth-wrapper">
          <div className="auth-promotion center">
            {authComponent === "signIn" ? (
              <img
                className="auth-promotion-logo"
                src={ASSETS.signIn}
                alt="promotion-sign-in-logo"
              />
            ) : (
              <img
                className="auth-promotion-logo"
                src={ASSETS.signOut}
                alt="promotion-sing-up-logo"
              />
            )}
          </div>
          <div className="auth-form-wrapper center">
            {authComponent === "orgSignUp" ? (
              <img className="free-trial-bookmark" src={ASSETS.free_trial} />
            ) : null}

            <div
              className={
                authComponent === "signIn" &&
                !showOrganisationVerificationComponent
                  ? "auth-form-logo-wrapper"
                  : "auth-form-logo-wrapper-left"
              }
            >
              <img
                className="auth-form-logo"
                src={ASSETS.pals_logo}
                alt="logo"
              />
            </div>

            {showOrganisationVerificationComponent ? (
              <div className="auth-form-verify-org center">
                <div className="auth-form-verify-org-field-wrapper">
                  <Heading level={3}>Verification Code</Heading>
                </div>
                <div className="auth-form-verify-org-field-wrapper">
                  <TextField
                    size="large"
                    placeholder="Enter Organisation Code"
                    errorMessage="There is an error"
                    isRequired
                    value={verificationCode}
                    onChange={(event) => {
                      setVerifcationCode(event.target.value);
                    }}
                  />
                </div>
                {wrongVerificationCodeAlert ? (
                  <Alert
                    className="auth-form-verify-alert"
                    variation="error"
                    isDismissible={true}
                    onDismiss={() => {
                      setWrongVerificationCodeAlert(false);
                    }}
                    hasIcon={true}
                    heading="Wrong Verification Code"
                  >
                    Please enter the correct verification code
                  </Alert>
                ) : (
                  <div></div>
                )}
                <AwsButton
                  isDisabled={awsVerifyButtonState}
                  isFullWidth={true}
                  isLoading={awsVerifyButtonState}
                  variation="primary"
                  size="medium"
                  loadingText="Verifying"
                  onClick={verifyOrganisation}
                  ariaLabel=""
                  style={{ marginTop: "1rem" }}
                >
                  Verify
                </AwsButton>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <hr className="division-line" />
                  <p className="auth-promotion-auth-page">OR</p>
                  <hr className="division-line" />
                </div>
                <div className="check-invite-text">
                  <HiMailOpen
                    style={{
                      marginRight: "5px",
                      marginBottom: "5px",
                      fontSize: "2rem",
                    }}
                  />
                  <p>Click on the invite link sent to your mail</p>
                </div>
              </div>
            ) : authComponent === "signIn" ? (
              <Authenticator
                className="auth-form"
                signUpAttributes={["name", "email"]}
                loginMechanisms={["email"]}
                formFields={formFields}
                initialState={currentSetting === "true" ? "signUp" : "signIn"}
                //services={services}
              />
            ) : authComponent === "orgSignUp" ? (
              <div className="auth-form-verify-org center ">
                <div className="organisation-auth-form-verify-org-field-wrapper">
                  <TextField
                    placeholder="Organisation Name"
                    value={update_org_details["organisation_name"]}
                    onChange={(event) =>
                      update_org_details(
                        "organisation_name",
                        event.target.value
                      )
                    }
                  />
                  <TextField
                    placeholder="Number of Employees"
                    value={update_org_details["number_of_employee"]}
                    onChange={(event) =>
                      update_org_details(
                        "number_of_employee",
                        event.target.value
                      )
                    }
                  />
                  <TextField
                    placeholder="Organisation Email"
                    value={update_org_details["organisation_email"]}
                    onChange={(event) =>
                      update_org_details(
                        "organisation_email",
                        event.target.value
                      )
                    }
                  />
                  <TextField
                    placeholder="Your Name"
                    // size="small"
                    value={update_org_details["your_name"]}
                    onChange={(event) =>
                      update_org_details("your_name", event.target.value)
                    }
                  />
                  <TextField
                    placeholder="Your Designation"
                    value={update_org_details["your_designation"]}
                    onChange={(event) =>
                      update_org_details("your_designation", event.target.value)
                    }
                  />
                  <p>
                    <Checkbox
                      value={update_org_details["terms"]}
                      onChange={(event) =>
                        update_org_details("terms", event.target.checked)
                      }
                    />
                    <a href="https://www.imbesideyou.com/terms-english">
                      Agree to Privacy Policy
                    </a>
                  </p>
                  <p>
                    <Checkbox
                      value={update_org_details["privacy"]}
                      onChange={(event) =>
                        update_org_details("privacy", event.target.checked)
                      }
                    />
                    <a href="https://www.imbesideyou.com/privacy-english">
                      Agree to Terms & Conditions
                    </a>
                  </p>

                  <AwsButton
                    isFullWidth={true}
                    isLoading={loadCreateOrg}
                    variation="primary"
                    size="medium"
                    ariaLabel="Create account"
                    onClick={() => {
                      createOrgHelper();
                    }}
                    isDisabled={
                      !(
                        org_details.number_of_employee &&
                        org_details.organisation_email &&
                        org_details.organisation_name &&
                        org_details.your_name &&
                        org_details.your_designation &&
                        org_details.privacy &&
                        org_details.terms
                      )
                    }
                  >
                    Create account
                  </AwsButton>
                </div>
              </div>
            ) : null}
            {authComponent === "signIn" &&
            !showOrganisationVerificationComponent &&
            currentSetting !== "true" ? (
              <div className="auth-promotion-auth-page">
                <div>
                  Join your organisation?{" "}
                  <span onClick={toVerfiyOrganisationCode}>Sign Up Here</span>
                </div>
              </div>
            ) : (
              <div className="auth-promotion-auth-page">
                <div>
                  Already a member ?{" "}
                  <span onClick={toSignInComponent}>Sign In Here</span>
                </div>
              </div>
            )}

            {authComponent === "signIn" && currentSetting !== "true" ? (
              showOrganisationVerificationComponent ? null : (
                <div className="free-trial">
                  <p className="free-trial-text">2 Months Free Trial</p>
                  <AwsButton
                    isFullWidth={true}
                    variation="primary"
                    size="medium"
                    loadingText="Try Pals for your organisation? Click here"
                    onClick={() => setAuthComponent("orgSignUp")}
                    ariaLabel="Try Pals for your organisation? Click here"
                    style={{ paddingLeft: "50px", paddingRight: "50px" }}
                  >
                    Try Pals for your organisation? Click here
                  </AwsButton>
                </div>
              )
            ) : null}
          </div>
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </Provider>
  );
}

export default AuthApp;