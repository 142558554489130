import { createBrowserHistory } from "history";
import { configureStore } from "@reduxjs/toolkit";

import userReducer from "./user/userReducer";
import graphReducer from "./graph/graphReducer";
import teamReducer from "./team/teamReducer";
import insightReducer from "./insights/insightReducer";
import miscReducer from "./misc/miscReducer";
import goodNoteReducer from "./goodNote/goodNoteReducer";

export const history = createBrowserHistory();

// combineReducers will be handled internally by configureStore
const rootReducer = (history) => ({
  user: userReducer,
  graph: graphReducer,
  teams: teamReducer,
  insights: insightReducer,
  misc: miscReducer,
  goodNote: goodNoteReducer
});

const preloadedState = {};

const store = configureStore({
  reducer: rootReducer(history),
  preloadedState,
});

export default store;
