const initialState = {
    loading: false,
    organisationProfilePicture: {},
    error: "",
    
  };
  
  const miscReducer = (state = initialState, action) => {
    switch (action.type) {
      case "FETCH_ORGANISATION_PROFILE_PICTURE_SUCCESS":
        return {
          ...state,
          organisationProfilePicture: action.payload,
        }
        default:
      return state;
  }
}
export default miscReducer  