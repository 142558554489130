const initialState = {
  loaded: false,
  team_details: [],
  team_members: {},
  graph: {},
};

const teamReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_TEAM_DETAILS_SUCCESS":
      // console.log(action.payload);
      return {
        ...state,
        team_details: action.payload,
      };
    case "FETCH_TEAM_MEMBERS_SUCCESS":
      return {
        ...state,
        team_members: {
          ...state.team_members,
          ...action.payload,
        },
      };
    case "FETCH_TEAM_METRICS_SUCCESS":
      return {
        ...state,
        loaded: true,
        graph: {
          ...state.graph,
          ...action.payload,
        },
      };
    case "FETCH_TEAM_METRICS_LOADING":
      return {
        ...state,
        loaded: false,
      };
    default:
      return state;
  }
};

export default teamReducer;
