export const fetchGraphDataSuccess = (data) => {
  return {
    type: "FETCH_GRAPH_DATA_SUCCESS",
    payload: data,
  };
};

export const updateDateSuccess = (data) => {
  return {
    type: "UPDATE_DATE",
    payload: data,
  };
};

export const fetchGraphDataSuccessV2 = (data) => {
  return {
    type: "FETCH_GRAPH_DATA_SUCCESS_V2",
    payload: data,
  };
};

export const fetchGraphDataLoadingV2 = (data) => {
  return {
    type: "FETCH_GRAPH_DATA_LOADING_V2",
    payload: data,
  };
};

export const fetchGraphMetricParamsV2 = (data) => {
  return {
    type: "FETCH_GRAPH_METRIC_PARAMS_V2",
    payload: data,
  };
};

export const fetchMeetingGraph = (data) => {
  console.log("hello action");
  return {
    type: "FETCH_MEETING_GRAPH",
    payload: data,
  }
}