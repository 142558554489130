import axios from "axios";
import config from "../utils/config";

export const getNetworkData = async (
  organisation_id,
  nodes,
  edges,
  communication_load,
  start_time,
  end_time
) => {
  const baseURL = config.API_URL;
  const path = "/network_graph";
  const parameters =
    "?organisation_id=" +
    organisation_id +
    "&nodes=" +
    nodes +
    "&edges=" +
    edges +
    "&communication_load=" +
    communication_load +
    "&start_time=" +
    start_time +
    "&end_time=" +
    end_time;

  const response = await axios.get(`${baseURL + path + parameters}`);

  return response;
};

export const getNetworkDataV2 = async (
  type,
  payload,
  start_time,
  end_time,
  filterOptions = []
) => {
  const baseURL = config.API_URL;
  const path = "/network_graph_v2";

  var new_payload = payload;
  console.log(payload);
  new_payload = new_payload.replaceAll("+", "%2B");

  var parameters =
    "?type=" +
    type +
    "&payload=" +
    new_payload +
    "&start_time=" +
    start_time +
    "&end_time=" +
    end_time;

  if (filterOptions.length !== 0) {
    parameters = parameters + "&filter=" + filterOptions;
  }
  
  console.log(`${baseURL + path + parameters}`);
  const response = await axios.get(`${baseURL + path + parameters}`);
  console.log(response);

  return response;
};
