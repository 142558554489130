import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFilePicker } from "use-file-picker";
import { uploadTranscriptAPI } from "../../api/uploadTranscript";

import "./UploadTranscript.css";

const UploadTranscript = () => {
  const [date, setDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [duration, setDuration] = useState("");
  const [topic, setTopic] = useState("");
  const [source, setSource] = useState("");

  const [status, setStatus] = useState("");

  const user_details = useSelector((state) => {
    return state.user.user_details;
  });

  const [
    openFileSelector,
    { filesContent, plainFiles, loading, errors, clear },
  ] = useFilePicker({
    accept: [".vtt", ".docx"],
    readAs: "DataURL",
  });

  const uploadTranscript = async () => {
    const organisation_id = user_details.organisation_id;
    const user_id = user_details.user_id;

    //checking all details are there or not
    if (!filesContent.length) {
      alert("No file selected");
    } else if (date === "") {
      alert("No date selected");
    } else if (startTime === "") {
      alert("No start time mentioned");
    } else if (duration === "") {
      alert("No duration mentioned");
    } else {
      setStatus("uploading");
      //Calling the api
      uploadTranscriptAPI(
        organisation_id,
        user_id,
        date,
        startTime,
        duration,
        topic,
        source
      )
        .then(async (res) => {
          //Getting presigned URL
          const presignedURL = res.data;

          const blob = await (await fetch(filesContent[0].content)).blob();

          //Uploading to S3
          const result = await fetch(presignedURL, {
            method: "PUT",
            body: blob,
          });

          console.log(result.status);
          if (result.status === 200) {
            setStatus("");
            //setting all data to default if success
            setDate("");
            setStartTime("");
            setDuration("");
            setTopic("");
            setSource("");
            clear();
            alert("File Uploaded");
          } else {
            alert("Something went wrong !");
          }
        })
        .catch((err) => {
          console.log(err);
          alert(err);
        });
    }
  };

  return (
    <div className="upload-transcript-wrapper">
      <div className="upload-transcript-heading">Upload Transcript</div>
      <div className="upload-transcript-form">
        <div className="upload-transcript-form-input-div">
          <span className="sub-heading">Date (YY/MM/DD)</span>
          <input
            className="normal-input"
            value={date}
            onChange={(event) => {
              setDate(event.target.value);
            }}
          />
        </div>
        <div className="upload-transcript-form-input-div">
          <span className="sub-heading">Start Time (HH:MM:SS)</span>
          <input
            className="normal-input"
            value={startTime}
            onChange={(event) => {
              setStartTime(event.target.value);
            }}
          />
        </div>
        <div className="upload-transcript-form-input-div">
          <span className="sub-heading">Duration (in Minutes)</span>
          <input
            className="normal-input"
            value={duration}
            onChange={(event) => {
              setDuration(event.target.value);
            }}
          />
        </div>
        <div className="upload-transcript-form-input-div">
          <span className="sub-heading">Topic</span>
          <input
            className="normal-input"
            value={topic}
            onChange={(event) => {
              setTopic(event.target.value);
            }}
          />
        </div>
        <div className="upload-transcript-form-input-div">
          <span className="sub-heading">Source</span>
          <select
            name="source"
            id="source"
            className="normal-input"
            onChange={(event) => {
              setSource(event.target.value);
            }}
          >
            <option value="zoom_upload_transcript" key="zoom">
              Zoom
            </option>
            <option value="teams_upload_transcript" key="teams">
              MS Teams
            </option>
            <option value="meet_upload_transcript" key="teams">
              Google Meet
            </option>
          </select>
        </div>
        <div className="upload-transcript-form-choose-file">
          <button
            className="upload-transcript-form-choose-file-button"
            onClick={() => openFileSelector()}
          >
            Select file
          </button>
          {filesContent.map((file, index) => (
            <div className="upload-transcript-form-choose-file-content">
              {file.name}
            </div>
          ))}
        </div>
        {status === "uploading" ? (
          <div className="upload-transcript-form-upload-button-uploading">
            Uploading
          </div>
        ) : (
          <div
            className="upload-transcript-form-upload-button"
            onClick={uploadTranscript}
          >
            Upload
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadTranscript;
