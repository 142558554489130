import axios from "axios";
import config from "../utils/config";

export const verifyOrganisationAPI = async (verification_code) => {
  const baseURL = config.API_URL;
  const path = "/verify_organisation_code";
  const parameters = "?verification_code=" + verification_code;

  const response = await axios.get(`${baseURL + path + parameters}`);

  return response;
};
