import axios from "axios";
import config from "../utils/config";

export const readUserDetailsAPI = async (user_id) => {
  const baseURL = config.API_URL;
  const path = "/user_details";
  const parameters = "?user_id=" + user_id;

  const response = await axios.get(`${baseURL + path + parameters}`);
  console.log(user_id, response);

  return response;
};

export const userMeetingsAPI = async (user_id) => {
  const baseURL = config.API_URL;
  const path = "/user_meetings";
  const parameters = "?user_id=" + user_id;

  const response = await axios.get(`${baseURL + path + parameters}`);

  return response;
};

export const userMeetingsWithTagsAPI = async (user_id) => {
  const baseURL = config.API_URL;
  const path = "/user_meeting_with_tags";
  const parameters = "?user_id=" + user_id;

  const response = await axios.get(`${baseURL + path + parameters}`);

  return response;
};

export const detailedAnalysisAPI = async (user_id) => {
  const baseURL = config.API_URL;
  const path = "/detailed_analysis";
  const parameters = "?user_id=" + user_id;

  const response = await axios.get(`${baseURL + path + parameters}`);

  return response;
};

export const updateUserDetailsAPI = async (
  user_id,
  fullname,
  age,
  gender,
  team,
  designation,
  email,
  profile_pic
) => {
  const baseURL = config.API_URL;
  const path = "/user_details";
  // const parameters = "?organisation_id=" + org_id;

  const params = {
    user_id: user_id,
    fullname: fullname,
    age: age,
    gender: gender,
    team: team,
    designation: designation,
    email: email,
    profile_pic: profile_pic,
  };

  console.log("updaing user details with - ", params);

  const data = await axios.put(`${baseURL + path}`, params);

  return data;
};

// read_org_users
export const fetchAllUsersAPI = async (organisation_id) => {
  const baseURL = config.API_URL;
  const path = "/read_org_users";
  const parameters = "?organisation_id=" + organisation_id;

  const response = await axios.get(`${baseURL + path + parameters}`);

  return response;
};

export const getProfilePicturePresignUrl = async (
  user_id,
  organisation_id,
  imageFormat
) => {
  const baseURL = config.API_URL;
  const path = "/update_profile_picture";
  const parameters =
    "?user_id=" +
    user_id +
    "&org_id=" +
    organisation_id +
    "&image_format=" +
    imageFormat;

  console.log(
    "calling getProfilePicturePresignUrl with user_id - ",
    user_id,
    "organisation_id- ",
    organisation_id,
    "  image format - ",
    imageFormat
  );

  const response = await axios.get(`${baseURL + path + parameters}`);

  return response;
};

export const userDesignationReadAPI = async (user_id, organisation_id) => {
  const baseURL = config.API_URL;
  const path = `/user_designations?organisation_id=${organisation_id}&user_id=${user_id}`;
  const response = await axios.get(`${baseURL + path}`);
  return response;
};

export const userDesignationUpdateAPI = async (
  user_id,
  designation_ids,
  organisation_id
) => {
  const baseURL = config.API_URL;
  const path = "/user_designations";
  const body = {
    user_id: user_id,
    designation_ids: designation_ids,
    organisation_id: organisation_id,
  };
  const response = await axios.put(`${baseURL + path}`, body);
  return response;
};

export const usersOfDesignationReadAPI = async (
  organisation_id,
  designation_ids
) => {
  const baseURL = config.API_URL;
  const path = "/user_designations";
  const body = {
    organisation_id: organisation_id,
    designation_ids: designation_ids,
  };
  const response = await axios.post(`${baseURL + path}`, body);
  return response;
};

export const getProfilePicturePresignedUrl = async (user_id, org_id) => {
  const baseURL = config.API_URL;
  const path = `/update_profile_picture?org_id=${org_id}&user_id=${user_id}`;
  const response = await axios.get(`${baseURL + path}`);
  return response;
};

export const generateProfilePictureUrls = async (target_type, target_id) => {
  // target_type can be 'user', 'team', 'organisation'
  const baseURL = config.API_URL;
  const path = `/get_profile_pic_urls?target_id=${target_id}&target_type=${target_type}`;
  const response = await axios.get(`${baseURL + path}`);
  return response;
}
