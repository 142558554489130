import logo from "./logo.png";
import analyticsWhite from "./analyticsWhite.svg";
import arrow2 from "./Arrow 2.png";
import layer2 from "./Layer 2.png";
import frame from "./Frame.png";
import happy from "./Happy.png";
import comments from "./Comments.png";
import settingsWhite from "./settingsWhite.svg";
import dashboardBlue from "./dashboardBlue.svg";
import settingsBlue from "./settingsBlue.svg";
import analyticsBlue from "./analyticsBlue.svg";
import dashboardWhite from "./dashboardWhite.svg";
import allDepartment from "./allDepartment.svg";
import allEmployees from "./allEmployees.svg";
import collaborators from "./collaborators.svg";
import communicationOverload from "./communicationOverload.svg";
import isolatedEmployees from "./isolatedEmployees.svg";
import riskOfTurnover from "./riskOfTurnover.svg";
import communicationOverloadGraphic from "./communicationOverloadGraphic.svg";
import engagementGraphic from "./engagementGraphic.svg";
import isolationGraphic from "./isolationGraphic.svg";
import retentionGraphic from "./retentionGraphic.svg";
import meetingHoursGraphic from "./meetingHoursGraphic.svg";
import peopleCommunicatedGraphic from "./peopleCommunicatedGraphic.svg";
import orangeCircle from "./orangeCircle.svg";
import messages from "./messages.svg";
import calls from "./calls.svg";
import insights from "./insights.svg";
import signIn from "./sign_in.svg";
import signOut from "./sign_up.svg";
import zoomCircleLogo from "./zoomCirlceLogo.png";
import msTeamsLogo from "./msTeamsLogo.png";
import purpleCircle from "./purpleCircle.svg";
import fullscreen from "./fullscreen.png";
import ZoomTextLogo from "./ZoomTextLogo.png";
import MSTeamsTextLogo from "./MSTeamsTextLogo.png";
import RiskOfTurnoverGraphic from "./RiskOfTurnoverGraphic.svg";
import CommunicationLoadGraphic from "./CommunicationLoadGraphic.svg";
import HierarchyGraphics from "./HierarchyGraphics.svg";
import loading from "./loading.gif";
import analyzing from "./analyzing.webp";
import ProfileUploadPng from "./profile-upload.png";
import upload from "./upload_icon.svg";
import uploadBlue from "./upload_icon_blue.svg";
import sidebar_bg from "./sidebar-bg.svg";
import ProfileIconPng from "./profileIcon.png";
import spinnerLoaderSvg from "./spinnerLoader.svg";
import CoffeeGraphic from "./coffee_graphic.svg";
import googleCalendarLogo from "./googleCalendarLogo.png";
import PeerGraphic1 from "./peer_graphic1.svg";
import PeerGraphic2 from "./peer_graphic2.svg";
import googleMeetLogo from "./googleMeetLogo.png";
import bulletPoint from "./bulletPoint.png";
import googleDriveLogo from "./googleDriveLogo.png";
import AmongUsImg from "./amogus.svg";
import GeoGuesserImg from "./geoguesser.svg";
import ScrbblImg from "./scrbbl.png";
import TicTakToeImg from "./ticktaktoe.svg";
import TeamBondingGeneralImg from "./teamBondingGeneral.svg";

import goodNote from "./goodNote.png";
import goodNoteArrowRight from "./goodNoteArrowRight.png";
import surveyAndFeedbackWhite from "./surveyAndFeedbackWhite.svg";
import surveyAndFeedbackBlue from "./surveyAndFeedbackBlue.svg";
import TickMark from "./tickMark.svg";
import BackIcon from "./backIcon.svg";

import wlcm_to_pls from "./wlcm_to_pls.png";
import user_signup_banner from "./user_signup_banner.png";
import pals_logo from "./pals_logo.svg"
import free_trial from "./free_trial.png"

const ASSETS = {
  arrow2,
  layer2,
  frame,
  happy,
  comments,
  TeamBondingGeneralImg,
  AmongUsImg,
  GeoGuesserImg,
  ScrbblImg,
  TicTakToeImg,
  communicationOverloadGraphic,
  MSTeamsTextLogo,
  ZoomTextLogo,
  msTeamsLogo,
  zoomCircleLogo,
  engagementGraphic,
  PeerGraphic1,
  PeerGraphic2,
  isolationGraphic,
  retentionGraphic,
  meetingHoursGraphic,
  peopleCommunicatedGraphic,
  logo,
  analyticsWhite,
  settingsWhite,
  dashboardBlue,
  settingsBlue,
  analyticsBlue,
  dashboardWhite,
  allDepartment,
  allEmployees,
  collaborators,
  communicationOverload,
  isolatedEmployees,
  riskOfTurnover,
  orangeCircle,
  messages,
  calls,
  CoffeeGraphic,
  insights,
  signIn,
  signOut,
  purpleCircle,
  fullscreen,
  loading,
  analyzing,
  upload,
  uploadBlue,
  sidebar_bg,
  spinnerLoaderSvg,
  googleCalendarLogo,
  googleMeetLogo,
  bulletPoint,
  googleDriveLogo,
  goodNote,
  goodNoteArrowRight,
  surveyAndFeedbackWhite,
  surveyAndFeedbackBlue,
  TickMark,
  BackIcon,
  wlcm_to_pls,
  user_signup_banner,
  pals_logo,
  free_trial,
};

export default ASSETS;
export {
  HierarchyGraphics,
  CommunicationLoadGraphic,
  RiskOfTurnoverGraphic,
  ProfileUploadPng,
  ProfileIconPng,
  sidebar_bg,
  spinnerLoaderSvg,
};
