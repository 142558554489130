import React, { Suspense } from "react";
import Sidebar from "./components/sidebar/Sidebar";
// import Dashboard from "./screens/dashboard/Dashboard";
// import Analytics from "./screens/analytics/Analytics";
// import Activites from "./screens/activities/Activities";
// import Settings from "./screens/settings/Settings";
// import ConnectToMSTeams from "./screens/connectToMSTeams/ConnectToMSTeams";
// import ConnectToGoogleCalendar from "./screens/connectToGoogleCalendar/ConnectToGoogleCalendar";
// import SurveyAndFeedback from "./screens/surveysAndFeedback/SurveyAndFeedback"
import AuthApp from "./components/auth/AuthApp";
import { useEffect, useState } from "react";

import { Routes, Route, useLocation, BrowserRouter } from "react-router-dom";

import { Amplify } from "aws-amplify";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";

import awsExports from "./utils/aws-exports";

import "./App.css";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchUserDetails,
  fetchUserDetailsFromDb,
} from "./store/user/userEffect";
import { fetchOrganisationProfilePicture } from "./store/misc/miscEffect";
import { generateProfilePictureUrls } from "./api/userDetails";
import { readUserDetailsAPI } from "./api/userDetails";

import { getNetworkData, getNetworkDataV2 } from "./api/networkGraph";
import {
  fetchGraphDetails,
  updateDate,
  fetchGraphDetailsV2,
  fetchGraphDetailsV2Loading,
  fetchGraphDetailsV2MetricParams,
} from "./store/graph/graphEffect";
import { UpdateModeEnum } from "chart.js";
import UploadTranscript from "./screens/uploadTranscript/UploadTranscript";
import ConnectToGoogleMeet from "./screens/connectToGoogleMeet/ConnectToGoogleMeet";
import LoadingSpinner from "./components/UI/LoadingSpinner";

// Lazy load components
const Dashboard = React.lazy(() => import("./screens/dashboard/Dashboard.js"));
const Analytics = React.lazy(() => import("./screens/analytics/Analytics"));
const Activites = React.lazy(() => import("./screens/activities/Activities"));
const Settings = React.lazy(() => import("./screens/settings/Settings"));
const ConnectToMSTeams = React.lazy(() =>
  import("./screens/connectToMSTeams/ConnectToMSTeams")
);
const ConnectToZoom = React.lazy(() =>
  import("./screens/connectToZoom/ConnectToZoom")
);
const ConnectToGoogleCalendar = React.lazy(() =>
  import("./screens/connectToGoogleCalendar/ConnectToGoogleCalendar")
);
const SurveyAndFeedback = React.lazy(() =>
  import("./screens/surveysAndFeedback/SurveyAndFeedback")
);

Amplify.configure(awsExports);

const CommunicationSolutionApp = ({ logOut }) => {
  const date = useSelector((e) => {
    return e.graph.date;
  });

  const fetchProfileInfo = async (user_id) => {
    // let response = await readUserDetailsAPI(user_id);
    // let data = response.data;
    //console.log(data);
    // const reader = new FileReader();
    // let dataUrl = data[0].profile_pic.substring(2);

    // if (data.length === 0) {
    //   return;
    // }
    // console.log(data[0]);

    readUserDetailsAPI(user_id).then((res) => {
      let data = res.data;
      console.log(data);

      dispatch(fetchUserDetailsFromDb(data[0]));

      dispatch(
        fetchGraphDetailsV2MetricParams({
          type: "organisation",
          payload: data[0]["organisation_id"],
          start_date: date.start_date,
          end_date: date.end_date,
          filterOptions: [],
        })
      );
    });
  };

  const { user, route, toSignUp, toSignIn, signOut } = useAuthenticator(
    (context) => [context.user]
  );

  const user_details = useSelector((state) => {
    return state.user.user_details;
  });

  let organisation_id = user_details?.organisation_id;

  const dispatch = useDispatch();

  //console.log(user);

  const fetchProfilePicture = async (organisation) => {
    let response = await generateProfilePictureUrls(
      "organisation",
      organisation
    );
    console.log("profile picture fetch results - ", response.data);
    dispatch(fetchOrganisationProfilePicture(response.data));
  };

  useEffect(() => {
    dispatch(fetchUserDetails(user.attributes));
    fetchProfileInfo(user.username);
  }, [user]);

  useEffect(() => {
    fetchProfilePicture(organisation_id);
  }, [organisation_id]);

  // console.log(user);
  useSelector((state) => {
    console.log(state);
  });

  const metric_params = useSelector((e) => {
    return e.graph.metric_params;
  });

  const isGraphLoaded = useSelector((e) => {
    return e.graph.loaded;
  });

  const getData = async () => {
    if (user_details === null || Object.keys(metric_params).length === 0) {
      return;
    }

    dispatch(fetchGraphDetailsV2Loading());

    console.log(metric_params);

    const filterOptions =
      "filterOptions" in metric_params ? metric_params.filterOptions : [];

    const resultV2 = await getNetworkDataV2(
      metric_params.type,
      metric_params.payload,
      date.start_date, // TeamDetails.js also update
      date.end_date,
      // 1669766400,
      // 1669809599
      filterOptions
    );

    console.log(resultV2.data);

    dispatch(fetchGraphDetailsV2(resultV2.data));
  };

  useEffect(() => {
    getData();
  }, [user_details, date.start_date, date.end_date, metric_params]);

  return (
    <div className="app">
      <Sidebar />
      <div className="main-content-wrapper">
        <Suspense fallback={<LoadingSpinner />}>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/analytics" element={<Analytics />} />
            <Route path="/activities" element={<Activites />} />
            {/* <Route path="/settings/zoom" element={<Settings  } /> */}
            {/* // <Route path="/settings/access" element={<Settings  />} /> */}
            <Route path="/settings" element={<Settings logOut={logOut} />} />
            <Route path="/upload" element={<UploadTranscript />} />
            <Route path="/connect-to-ms-teams" element={<ConnectToMSTeams />} />
            <Route
              path="/connect-to-google-calendar"
              element={<ConnectToGoogleCalendar />}
            />
            <Route
              path="/connect-to-google-meet"
              element={<ConnectToGoogleMeet />}
            />
            <Route path="/connect-to-zoom" element={<ConnectToZoom />} />
            <Route path="/survey-feedback" element={<SurveyAndFeedback />} />
          </Routes>
        </Suspense>
      </div>
    </div>
  );
};

const App = () => {
  return (
    <Authenticator.Provider>
      <AuthApp App={CommunicationSolutionApp} />
    </Authenticator.Provider>
  );
};

export default App;
