// import {
// 	getAvgFaceEmotions,
// 	getAvgFaceEmotionsGroupByParticipant,
// 	getFaceEmotionsInBetween,
// } from "../../api/FaceEmotions";

import {
  fetchUserDetailsSuccess,
  fetchUserDetailsDBSuccess,
  updateLanguageSuccess,
  fetchAllUsersSuccess,
  fetchtagOptionsSuccess,
} from "./userAction";

export const fetchUserDetails = (details) => {
  return (dispatch) => {
    dispatch(fetchUserDetailsSuccess(details));
  };
};

export const fetchUserDetailsFromDb = (details) => {
  return (dispatch) => {
    dispatch(fetchUserDetailsDBSuccess(details));
  };
};

export const updateLanguage = (details) => {
  return (dispatch) => {
    dispatch(updateLanguageSuccess(details));
  };
};

export const fetchAllUsers = (details) => {
  return (dispatch) => {
    dispatch(fetchAllUsersSuccess(details));
  };
};

export const setTagOptions = (details) => {
  return (dispatch) => {
    dispatch(fetchtagOptionsSuccess(details));
  };
};