export const fetchUserDetailsSuccess = (data) => {
  return {
    type: "FETCH_USER_DETAILS_SUCCESS",
    payload: data,
  };
};

export const fetchUserDetailsDBSuccess = (data) => {
  return {
    type: "FETCH_USER_DETAILS_DB_SUCCESS",
    payload: data,
  };
};

export const updateLanguageSuccess = (data) => {
  return {
    type: "UPDATE_USER_LANGUAGE_SUCCESS",
    payload: data,
  };
};

export const fetchAllUsersSuccess = (data) => {
  return {
    type: "FETCH_ALL_USERS_SUCCESS",
    payload: data,
  };
};

export const fetchtagOptionsSuccess = (data) => {
  return {
    type: "FETCH_TAG_OPTIONS_SUCCESS",
    payload: data,
  };
};