import {
  fetchGraphDataSuccess,
  updateDateSuccess,
  fetchGraphDataSuccessV2,
  fetchGraphDataLoadingV2,
  fetchGraphMetricParamsV2,
  fetchMeetingGraph,
} from "./graphAction";

export const fetchGraphDetails = (details) => {
  return (dispatch) => {
    dispatch(fetchGraphDataSuccess(details));
  };
};

export const fetchGraphDetailsV2 = (details) => {
  return (dispatch) => {
    dispatch(fetchGraphDataSuccessV2(details));
  };
};

export const fetchGraphDetailsV2Loading = (details) => {
  return (dispatch) => {
    dispatch(fetchGraphDataLoadingV2(details));
  };
};

export const fetchGraphDetailsV2MetricParams = (details) => {
  return (dispatch) => {
    dispatch(fetchGraphMetricParamsV2(details));
  };
};

export const updateDate = (details) => {
  return (dispatch) => {
    dispatch(updateDateSuccess(details));
  };
};

export const fetchMeetingGraphData = (details) => {
  console.log("hello effect");
  return (dispatch) => {
    dispatch(fetchMeetingGraph(details));
  };
};
