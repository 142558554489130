import axios from "axios";
import config from "../utils/config";

export const connectToGoogleMeetOauth = async (
  user_id,
  user_code,
  redirect_uri
) => {
  console.log(user_id, user_code);
  const baseURL = config.API_URL;
  const path = "/connect_to_google_meet";
  const params = {
    user_id: user_id,
    code: user_code,
    redirect_uri: redirect_uri,
  };
  const data = await axios.post(`${baseURL + path}`, params);

  return data;
};

export const connectionDetailsGoogleMeet = async (user_id, method) => {
  const baseURL = config.API_URL;
  const path = "/google_meet_connection_details";

  const params = {
    user_id: user_id,
    method: method,
  };

  const data = await axios.post(`${baseURL + path}`, params);

  return data;
};

export const connectToGoogleDriveFile = async (user_id, file_id) => {
  const baseURL = config.API_URL;
  const path = "/connect_to_google_drive_file";

  const params = {
    user_id: user_id,
    file_id: file_id,
  };

  const data = await axios.post(`${baseURL + path}`, params);

  return data;
};
