const initialState = {
    loaded: false,
    summary_insights: []
  };
  

  const insightReducer = (state = initialState, action) => {
    switch (action.type) {
      case "FETCH_SUMMARY_INSIGHTS_SUCCESS":
        // console.log(action.payload);
        return {
          ...state,
          data: action.payload,
          loaded: true
        };
       
      
      default:
        return state;
    }
  };

  export default insightReducer