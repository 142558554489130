import React, { useState } from "react";
import ASSETS, { sidebar_bg } from "../../assets/Assets";
import "./Sidebar.css";
import { Link, useLocation } from "react-router-dom";
import { GiGamepadCross } from "react-icons/gi";


export default function Sidebar() {
  const location = useLocation();
  const [hoveredTab, setHoveredTab] = useState(null);

  const handleTabHover = (pathname) => {
    setHoveredTab(pathname);
  };

  const handleTabLeave = () => {
    setHoveredTab(null);
  };

  const getTabName = (pathname) => {
    switch (pathname) {
      case "/":
        return "Dashboard";
      case "/analytics":
        return "Analytics";
      case "/upload":
        return "Upload";
      case "/survey-feedback":
        return "Survey & Feedback";
      case "/activities":
        return "Activities";
      case "/settings":
        return "Settings";
      default:
        return "";
    }
  };

  return (
    <div className="sidebar-wrapper">
      <img src={ASSETS.logo} className="sidebar-logo" />
      <div className="sidebar-menu">
        <img
          src={sidebar_bg}
          style={{
            position: "absolute",
            // top: 0,
            // left: -1.5,
            zIndex: -10,
            width: "100%",
            // height: "190%",
          }}
          alt="Sidebar background"
        />
        <Link to="/" style={{ textDecoration: "none" }}>
          <div
            className={
              location.pathname !== "/" ? "sidebar-tab" : "sidebar-tab active"
            }
            onMouseEnter={() => handleTabHover("/")}
            onMouseLeave={handleTabLeave}
          >
            {location.pathname !== "/" ? (
              <img className="sidebar-icon" src={ASSETS.dashboardWhite} alt="Dashboard Icon" />
            ) : (
              <img className="sidebar-icon" src={ASSETS.dashboardBlue} alt="Dashboard Icon" />
            )}
            {hoveredTab === "/" && <span className="sidebar-tooltip">{getTabName("/")}</span>}
          </div>
        </Link>
        <Link to="/analytics?list=team" style={{ textDecoration: "none" }}>
          <div
            className={
              !location.pathname.startsWith("/analytics")
                ? "sidebar-tab"
                : "sidebar-tab active"
            }
            onMouseEnter={() => handleTabHover("/analytics")}
            onMouseLeave={handleTabLeave}
          >
            {!location.pathname.startsWith("/analytics") ? (
              <img className="sidebar-icon" src={ASSETS.analyticsWhite} alt="Analytics Icon" />
            ) : (
              <img className="sidebar-icon" src={ASSETS.analyticsBlue} alt="Analytics Icon" />
            )}
            {hoveredTab === "/analytics" && <span className="sidebar-tooltip">{getTabName("/analytics")}</span>}
          </div>
        </Link>
        <Link to="/upload" style={{ textDecoration: "none" }}>
          <div
            className={
              location.pathname !== "/upload"
                ? "sidebar-tab"
                : "sidebar-tab active"
            }
            onMouseEnter={() => handleTabHover("/upload")}
            onMouseLeave={handleTabLeave}
          >
            {location.pathname !== "/upload" ? (
              <img className="sidebar-icon" src={ASSETS.upload} alt="Upload Icon" />
            ) : (
              <img className="sidebar-icon" src={ASSETS.uploadBlue} alt="Upload Icon" />
            )}
            {hoveredTab === "/upload" && <span className="sidebar-tooltip">{getTabName("/upload")}</span>}
          </div>
        </Link>
        <Link to="/survey-feedback" style={{ textDecoration: "none" }}>
          <div
            className={
              location.pathname !== "/survey-feedback"
                ? "sidebar-tab"
                : "sidebar-tab active"
            }
            onMouseEnter={() => handleTabHover("/survey-feedback")}
            onMouseLeave={handleTabLeave}
          >
            {location.pathname !== "/survey-feedback" ? (
              <img className="sidebar-icon" src={ASSETS.surveyAndFeedbackWhite} alt="Survey & Feedback Icon" />
            ) : (
              <img className="sidebar-icon" src={ASSETS.surveyAndFeedbackBlue} alt="Survey & Feedback Icon" />
            )}
            {hoveredTab === "/survey-feedback" && <span className="sidebar-tooltip">{getTabName("/survey-feedback")}</span>}
          </div>
        </Link>
        <Link to="/activities" style={{ textDecoration: "none" }}>
          <div
            className={
              location.pathname !== "/activities"
                ? "sidebar-tab"
                : "sidebar-tab active"
            }
            onMouseEnter={() => handleTabHover("/activities")}
            onMouseLeave={handleTabLeave}
          >
            {location.pathname !== "/activities" ? (
              <GiGamepadCross className="sidebar-icon" color="white" size={28} />
            ) : (
              <GiGamepadCross className="sidebar-icon" color="#5f6ce1" size={28} />
            )}
            {hoveredTab === "/activities" && (
              <span className="sidebar-tooltip">
                {getTabName("/activities")}
              </span>
            )}
          </div>
        </Link>

        <Link to="/settings" style={{ textDecoration: "none" }}>
          <div
            className={
              location.pathname !== "/settings"
                ? "sidebar-tab"
                : "sidebar-tab active"
            }
            onMouseEnter={() => handleTabHover("/settings")}
            onMouseLeave={handleTabLeave}
          >
            {location.pathname !== "/settings" ? (
              <img className="sidebar-icon" src={ASSETS.settingsWhite} alt="Settings Icon" />
            ) : (
              <img className="sidebar-icon" src={ASSETS.settingsBlue} alt="Settings Icon" />
            )}
            {hoveredTab === "/settings" && <span className="sidebar-tooltip">{getTabName("/settings")}</span>}
          </div>
        </Link>
      </div>
    </div>
  );
}