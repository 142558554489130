// DO NOT EDIT this file , unless you know what you are doing
const aws = {
  Auth: {
    mandatorySignIn: true,
    /*PRODUCTION*/
    region: "ap-northeast-1",
    userPoolId: "ap-northeast-1_zbIVWrhVU",
    userPoolWebClientId: "7euvgsvvd44qcecl94hqt8boem", //App Clied Id
    //identityPoolId: "Not made, we have make it",
  },
};

export default aws;
