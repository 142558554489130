const initialState = {
  loading: false,
  user: {},
  error: "",
  user_details: null,
  org: [],
  tag_options: [],
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_USER_DETAILS_SUCCESS":
      return {
        ...state,
        user: action.payload,
      };
    case "FETCH_USER_DETAILS_DB_SUCCESS":
      return {
        ...state,
        user_details: action.payload,
      };
    case "UPDATE_USER_LANGUAGE_SUCCESS":
      return {
        ...state,
        user_details: { ...state.user_details, language: action.payload },
      };
    case "FETCH_ALL_USERS_SUCCESS":
      return {
        ...state,
        org: [...action.payload],
      };
    case "FETCH_TAG_OPTIONS_SUCCESS":
      return {
        ...state,
        tag_options: [...action.payload],
      };
    default:
      return state;
  }
};

export default userReducer;
