import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { connectToGoogleMeetOauth } from "../../api/GoogleMeet";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

export default function ConnectToGoogleMeet() {
  const [searchParams, setSearchParams] = useSearchParams();
  const user_code = searchParams.get("code");
  const [progress, setProgress] = useState(0);

  const user_id = useSelector((state) => {
    if (state.user.user_details !== null) {
      return state.user.user_details.user_id;
    }

    return null;
  });

  const connectToGoogleHandler = async () => {
    console.log(user_id, user_code);

    setProgress(40);

    const baseURI = window.location.origin;
    const redirect_uri = `${baseURI}/connect-to-google-meet`;

    const res = await connectToGoogleMeetOauth(
      user_id,
      user_code,
      redirect_uri
    );

    setProgress(100);

    console.log(res);
  };

  useEffect(() => {
    console.log("ok 123");
    if (user_code !== null && user_id != null) {
      setProgress(20);

      console.log("hey there");
      connectToGoogleHandler();
    }
  }, [user_code, user_id]);

  useEffect(() => {
    if (progress === 100) {
      const baseURI = window.location.origin;
      const url = baseURI + "/settings?tab=googleMeet";

      window.location.replace(url);
    }
  }, [progress]);

  return (
    <div>
      {progress === 100
        ? "Integrated to Google Meet by connecting to google Drive. You can now browse to different page if not redirected automatically."
        : "Please dont change/ close window while progress is not complete"}

      <Box sx={{ width: "100%" }}>
        <LinearProgress variant="determinate" value={progress} />
      </Box>
      {progress === 100 ? "Connected to Google calendar" : null}
    </div>
  );
}
