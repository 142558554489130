import axios from "axios";
import config from "../utils/config";

export const createOrganisation = async (
  email,
  organisation_name,
  number_of_employee,
  your_name,
  designation
) => {
  const baseURL = config.API_URL;
  const path = "/org_signup_v2";

  const params = {
    email: email,
    organisation_name: organisation_name,
    number_of_employee: number_of_employee,
    your_name: your_name,
    designation: designation,
  };

  console.log("params ------> ", params);

  // const data = await axios.post(`${baseURL + path}`, params);

  // return data;
};

export const createUserAPI = async (verification_code) => {
  const baseURL = config.API_URL;
  const path = "/user_signup_v2";

  const params = {
    method: "check_invite",
    verification_code: verification_code,
  };

  console.log("params ------> ", params);

  const data = await axios.post(`${baseURL + path}`, params);

  return data;
};
