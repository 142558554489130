import axios from "axios";
import config from "../utils/config";

export const uploadTranscriptAPI = async (
  organisation_id,
  user_id,
  date,
  startTime,
  duration,
  topic,
  source
) => {
  const baseURL = config.API_URL;
  const path = "/upload_transcript";

  const params = {
    organisation_id: organisation_id,
    user_id: user_id,
    date: date,
    start_time: startTime,
    duration: duration,
    topic: topic,
    source:source
  };

  const data = await axios.post(`${baseURL + path}`, params);

  return data;
};
