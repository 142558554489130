import { Loader } from '@aws-amplify/ui-react';

const LoadingSpinner = () => {
  return (
    <div style={{display:"flex", alignItems:"center", justifyContent:"center", minHeight:"100%"}} >
      <Loader size="large"/>
    </div>
  );
};

export default LoadingSpinner